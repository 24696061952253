.iframe-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notebook {
  height: 100vh;
  width: 1000px;
}
